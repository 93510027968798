// Customizable Area Start
export const pencil = require("../assets/pencil.svg");
export const chevron = require("../assets/chevron.svg");
export const image = require("../assets/image.svg");
export const image1 = require("../assets/image1.png");
export const ArrowLeftIcon = require("../assets/arrowleft.svg");
export const uparrow = require("../assets/Chevron-up-big.svg");
export const cross = require("../assets/cross.svg");
export const StudioIcon = require("../assets/studio.svg");
export const downarrow = require("../assets/down-chevron.svg");
export const NotFoundIcon = require("../assets/Not-found.png");
export const ErrorIcon = require("../assets/Error-cross-circle.svg");
export const deletIcon = require("../assets/delete.png");
export const closeIcon = require('../assets/cross.png');
export const listShowIcon = require('../assets/list-show.png');
export const CalendarIcon = require('../assets/calendarIcon.png');
export const listViewIcon = require('../assets/list-hide.png')
export const ArrowForwardIcon = require('../assets/ArrowForwardIcon.png')
export const ArrowBackIcon = require('../assets/ArrowBackIcon.png')
export const RightCheck = require('../assets/rightcheck.png') 
export const download = require("../assets/download.png") 
export const NoTestOrder = require("../assets/no_testorder.png")
// Customizable Area End