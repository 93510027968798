// @ts-nocheck
import { BlockComponent } from '../../../../framework/src/BlockComponent'
import { RouterProps } from "react-router";
import { withHeadeActionBarProps } from "../../ss-cms-common-components/src/HOC/withHeadeActionBar.Web";
import { withLoaderProps } from "../Components/withBrandingSpinner.web";
import { withToastProps } from "../../ss-cms-common-components/src/HOC/withSnackBar.Web";
import { DialogProps } from "../../ss-cms-common-components/src/Dialog/withDialog";

export type Props = RouterProps &
  DialogProps &
  withHeadeActionBarProps &
  withLoaderProps &
  withToastProps & {
    id: string;
    // Customizable Area Start
    isAspectRatio: boolean;
    isBanner?: boolean; 
    onCancelProp?: () => void;
    // Customizable Area End
  };

interface S {
  // Customizable Area Start

  // Customizable Area End
}

interface SS {
  id: any;
}

export default class AddCropperController extends BlockComponent<Props, S, SS> {
  constructor(props: Props) {
      super(props);
      this.state = {
          zoom: 0
      }
  }
  zoomIn = () => {
    if (this?.cropper?.cropper?.zoom !== "undefined" && this.state.zoom < 1) {
        this.setState(prevState => {
            return {
                ...prevState,
                zoom: Math.min(prevState.zoom + 0.1, 1) // Ensure zoom doesn't exceed 1
            }
        }, () => {
            this?.cropper?.cropper?.zoom(0.1)
        })
    }
  }
  zoomOut = () => {
    if (this?.cropper?.cropper?.zoom !== "undefined" && this.state.zoom > 0.01) {
        this.setState(prevState => {
            return {
                ...prevState,
                zoom: Math.max(prevState.zoom - 0.1, 0.01) // Ensure zoom doesn't go below 0.01
            }
        }, () => {
            this?.cropper?.cropper?.zoom(-0.1)
        })
    }
  }
  handleZoomChange(event: any, newValue: number | number[]) {
    let newData;
    if(this.state.zoom > newValue){
        newData = this.state.zoom - newValue  ;
        newData = -newData
    }
    else {
        newData = newValue - this.state.zoom;
    }
    this.setState({
        zoom: newValue
    }, () => {
        this?.cropper?.cropper?.zoom(newData)
    });
  };
}